import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header>
    <Link to="/" className="logo">
      <h1>Rugăciuni</h1>
      <span>de</span>
      <h1>Dimineață</h1>
    </Link>
  </header>
)

export default Header
