import React from "react"

import Header from "./header"
import "./layout.css"
import SEO from "./seo"
import { Footer } from "./footer"

const Layout = ({ children, title, description, uri, image }) => {
  return (
    <>
      <SEO uri={uri} title={title} description={description} image={image} />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
