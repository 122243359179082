import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const bullet = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      d="M11.5 0l1.4 7.3 5.4-5.1L15 8.9l7.3-1-6.5 3.6 6.5 3.6-7.3-1 3.2 6.7-5.4-5.1-1.4 7.3-1.4-7.3-5.4 5.1L8 14.1.6 15 7 11.5.6 7.9l7.3 1-3.2-6.7 5.4 5.1L11.5 0z"
      fill="#000"
    />
  </svg>
)

export const Footer = () => {
  return (
    <footer>
      <div className="footer">
        {bullet}
        <StaticQuery
          query={graphql`
            {
              allPrismicRugaciune {
                edges {
                  node {
                    uid
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="panel">
              <h2>
                Cele mai rostite rugaciuni -{" "}
                {data.allPrismicRugaciune.edges.length}
              </h2>
              <div className="body">
                {data.allPrismicRugaciune.edges.map(item => (
                  <Link
                    key={item.node.uid}
                    to={"/rugaciune/" + item.node.uid}
                    className="fancy"
                  >
                    {item.node.data.title.text}
                  </Link>
                ))}
              </div>
            </div>
          )}
        />
        {bullet}
        <div className="panel">
          <small>
            Copyright &copy; 2020 Rugăciuni Dimineață. Toate drepturile sunt
            rezervate.
          </small>
        </div>
      </div>
    </footer>
  )
}
